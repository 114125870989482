import { useMemo } from "preact/hooks";
import { Partner } from "./DiscountCard";

export default function usePartner(hostname?: string): Partner {
  return useMemo(() => {
    const domain_parts = (hostname || document.location.hostname).split(".");
    if (domain_parts.length > 1 && domain_parts[1] === "rx4free") {
      return Partner.RX4Free;
    }
    if (domain_parts.length > 1 && domain_parts[1] === "clearpricerx") {
      return Partner.ClearPrice;
    }
    if (domain_parts.length > 1 && domain_parts[1] === "mydpcrx") {
      return Partner.MyDPCrx;
    }
    switch (domain_parts[0]) {
      case "synergyhealth":
        return Partner.Synergy;
      case "nextran":
        return Partner.Nextran;
      case "altrua":
        return Partner.Altrua;
      case "westgate":
        return Partner.WestGate;
      case "revive":
        return Partner.Revive;
      case "sunstate":
        return Partner.Sunstate;
      case "firstprimarycare":
        return Partner.FirstPrimaryCare;
      case "doctegrity":
        return Partner.Doctegrity;
      case "strada":
        return Partner.Strada;    
      case "healthyr":
        return Partner.Healthyr;
      case "indipop":
        return Partner.Indipop;
      case "rx4free":
        return Partner.RX4Free;
      case "bettyhealth":
        return Partner.BettyHealth;
      case "clearpricerx":
        return Partner.ClearPrice;
      case "mydpcrx":
        return Partner.MyDPCrx;
      case "upfronthealth":
        return Partner.Upfront;
      case "wellclarity":
        return Partner.WellClarity;
      default:
        return Partner.None;
    }
  }, [hostname]);
}
